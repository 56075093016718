<template>
  <div class="project-file">
    <a ref="download" style="display: none" />
    <ProjectMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件/文件夹名">
          <el-input
            size="small"
            v-model="fileName"
            placeholder="搜索文档库"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" icon="el-icon-search" @click="getFileList"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-upload
        action="/"
        :show-file-list="false"
        :auto-upload="true"
        :http-request="uploadFile"
      >
        <el-button size="small" type="primary" icon="el-icon-upload"
          >上传</el-button
        >
        <el-button
          size="small"
          plain
          type="primary"
          icon="el-icon-folder-add"
          @click.stop="showNewFolder"
          >新建文件夹</el-button
        >
      </el-upload>

      <div
        v-if="lastParentId || lastFileName || fileName"
        style="font-size: 14px; font-weight: 500"
      >
        <el-button type="text" @click="toLast" v-if="!fileName">返回上一级</el-button
        ><el-button type="text" @click="toRoot">全部文件</el-button> >
        <span type="text" v-if="!fileName">{{ lastFileName }}</span>
        <span type="text" v-if="fileName">搜索结果</span>
      </div>

      <div v-show="newFolderShow">
        <el-form :inline="true">
          <el-form-item>
            <el-input
              size="small"
              v-model="newFolderName"
              placeholder="新建文件夹"
              clearable
              ref="newFolderName"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              icon="el-icon-check"
              @click="createNewFolder('')"
            ></el-button>
            <el-button
              size="small"
              icon="el-icon-close"
              @click="cancelNewFolder"
            ></el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <el-table :data="fileList" style="width: 100%" @sort-change="sortChange">
        <!-- <el-table-column type="index" width="50"> </el-table-column> -->
        <el-table-column prop="fileName" label="名称" sortable="custom">
          <template slot-scope="scope">
            <a @click="changeParentId(scope.row)">
              <img
                class="fileIcon"
                src="../../assets/folder.png"
                v-if="scope.row.fileType == '文件夹'"
              />
              <img
                class="fileIcon"
                src="../../assets/file.png"
                v-if="scope.row.fileType != '文件夹'"
              />
              <span class="fileName">{{ scope.row.fileName }}</span>
            </a>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="时间"
          sortable="custom"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="fileType" label="类型" width="100">
        </el-table-column>
        <el-table-column prop="fileSize" label="大小" width="100">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="onShowReFileName(scope.row)"
              icon="el-icon-edit"
              >重命名</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="onDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50, 100]"
        :total="totals"
        @current-change="getFileList"
        @size-change="getFileList"
      >
      </el-pagination>
    </div>

    <!-- 重命名 -->
    <el-dialog
      title="重命名"
      :visible.sync="showReFileName"
      style="width: 800px; margin: 0 auto"
    >
      <el-form>
        <el-form-item>
          <el-input
            v-model="reFileName"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showReFileName = false">取消</el-button>
        <el-button size="small" type="primary" @click="onSaveReFileName"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scope>
.project-file {
  .el-form-item {
    margin-bottom: 0;
  }
  .fileIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .fileName {
    font-size: 14px;
    line-height: 16px;
  }
  .el-dialog__body {
    padding: 10px 20px;
  }
}
</style>

<script>
import ProjectMenu from "@/components/ProjectMenu.vue";

export default {
  name: "ProjectFile",
  components: {
    ProjectMenu,
  },
  data() {
    return {
      loading: null,
      projectList: [],
      fileList: [],
      currentProject: null,
      parentId: null,
      lastParentId: null,
      lastFileName: null,
      fileName: null,
      filterType: 1, //1 文件名升序  2文件名倒序  3时间升序 4时间倒序
      pageNum: 1,
      pageSize: 100,
      totals: 0,

      newFolderShow: false,
      newFolderName: "",

      showReFileName: false,
      reFileName: "",
      reFileRow: null,
    };
  },
  computed: {},
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (newVal, oldVal) {
      this.getFileList();
    },
    parentId: function (newVal, oldVal) {
      this.getFileList();
    },
    fileName: function (newVal, oldVal) {
      this.getFileList();
    }
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        pageNum: 1,
        pageSize: 100,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取文件及文件夹列表
    getFileList: function () {
      this.cancelNewFolder();

      var that = this;
      var data = {
        projectId: this.currentProject,
        parentId: this.parentId,
        filterType: this.filterType, //1 文件名升序  2文件名倒序  3时间升序 4时间倒序
        fileName: this.fileName,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };

      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/document/queryList",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.totals = res.data.totals;
          that.fileList = res.data.fileList;
          if (res.data.lastpId) {
            this.lastParentId = res.data.lastpId;
          } else {
            this.lastParentId = null;
          }
          if (res.data.lastFileName) {
            this.lastFileName = res.data.lastFileName;
          } else {
            this.lastFileName = null;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 变更排序模式
    sortChange: function (column) {
      if (column.order == null) {
        this.filterType = 1;
      }
      if (column.prop == "fileName" && column.order == "ascending") {
        this.filterType = 1;
      }
      if (column.prop == "fileName" && column.order == "descending") {
        this.filterType = 2;
      }
      if (column.prop == "updateTime" && column.order == "ascending") {
        this.filterType = 3;
      }
      if (column.prop == "updateTime" && column.order == "descending") {
        this.filterType = 4;
      }
      this.getFileList();
    },
    // 切换当前目录
    changeParentId: function (data) {
      if (data.fileType != "文件夹") {
        this.downloadFile(data)
        return;
      }
      this.parentId = data.id;
      this.cancelNewFolder();
    },
    // 返回根目录
    toRoot: function () {
      this.fileName = null;
      this.parentId = null;
      this.getFileList()
    },
    // 返回上一级
    toLast: function () {
      this.parentId = this.lastParentId;
    },
    // 显示创建文件夹
    showNewFolder: function () {
      this.newFolderShow = true;
      this.newFolderName = "";
      setTimeout(() => {
        this.$refs["newFolderName"].focus();
      }, 100);
    },
    // 取消创建新文件夹
    cancelNewFolder: function () {
      this.newFolderShow = false;
      this.newFolderName = "";
    },
    // 创建文件夹
    createNewFolder: function (id) {
      var that = this;
      var data = {
        folderId: id,
        projectId: this.currentProject,
        folderName: this.newFolderName,
        parentId: this.parentId,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/document/saveFolder",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.cancelNewFolder();
          that.getFileList();
          that.loading.close();
          that.$message({
            type: "success",
            message: "操作成功!",
          });
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 删除文件夹
    onDel: function (row) {
      // console.log("onDel =>", row);
      var that = this;
      var data = {
        folderId: "",
        papersId: "",
        projectId: this.currentProject,
      };
      var msg;
      if (row.fileType == "文件夹") {
        data.folderId = row.id;
        msg = row.fileName + " 文件夹内的全部内容";
      } else {
        data.papersId = row.id;
        msg = row.fileName + " 文件";
      }
      this.$confirm(`此操作将永久删除 ${msg} , 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });

          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/document/del",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.getFileList();
              that.loading.close();
            })
            .catch((err) => {
              console.log(err);
              that.loading.close();
            });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 显示重命名
    onShowReFileName: function (row) {
      console.log("onShowReFileName => ", row);
      this.showReFileName = true;
      this.reFileName = row.fileName;
      this.reFileRow = row;
    },
    // 保存重命名
    onSaveReFileName: function () {
      var that = this;
      var data;
      if (this.reFileRow.fileType == "文件夹") {
        data = {
          folderId: this.reFileRow.id,
          projectId: this.currentProject,
          folderName: this.reFileName,
        };
        that.loading = that.$loading({
          lock: true,
          text: "Loading...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        that
          .$axiosAdmin({
            method: "post",
            url: "api/admin/document/saveFolder",
            data: JSON.stringify(data),
          })
          .then((res) => {
            that.getFileList();
            that.loading.close();
            that.showReFileName = false;
            that.$message({
              type: "success",
              message: "操作成功!",
            });
          })
          .catch((err) => {
            console.log(err);
            that.loading.close();
          });
      } else {
        data = {
          papersId: this.reFileRow.id,
          projectId: this.currentProject,
          papersName: this.reFileName,
        };
        that.loading = that.$loading({
          lock: true,
          text: "Loading...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        that
          .$axiosAdmin({
            method: "post",
            url: "api/admin/document/savePapers",
            data: JSON.stringify(data),
          })
          .then((res) => {
            that.getFileList();
            that.loading.close();
            that.showReFileName = false;
            that.$message({
              type: "success",
              message: "操作成功!",
            });
          })
          .catch((err) => {
            console.log(err);
            that.loading.close();
          });
      }
    },
    // 上传文件
    uploadFile: function (file) {
      var that = this;
      var fileFormData = new FormData();
      fileFormData.append("file", file.file);
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axios({
          method: "post",
          url: "api/admin/sys-file/uploadwdk",
          data: fileFormData,
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          var fileData = {
            papersId: "",
            projectId: that.currentProject,
            papersName: res.data.data.fileName,
            folderId: that.parentId,
            papersSize: res.data.data.size,
            papersPath: res.data.data.url,
            serverPapersName: res.data.data.fileSerialName,
          };

          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/document/savePapers",
              data: JSON.stringify(fileData),
            })
            .then((res) => {
              that.getFileList();
              that.loading.close();
              that.$message({
                type: "success",
                message: "操作成功!",
              });
            })
            .catch((err) => {
              console.log(err);
              that.loading.close();
            });
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 下载文件
    downloadFile: function (data) {
      var that = this
      console.log('downloadFile =>', data);
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axios({
          method: "get",
          url: "api/admin/sys-file/qxdl/" + data.serverPapersName,
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          transformRequest: [
            function (data) {
              let ret = "";
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              return ret;
            },
          ],
        })
        .then((res) => {
          const blob = res.data;
          that.$refs.download.href = URL.createObjectURL(blob);
          that.$refs.download.download = data.fileName;
          that.$refs.download.click();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });

    }
  },
};
</script>